export class Exception<TPayload>{
  [key: string]: TPayload

  constructor(
    payload?: { [key: string]: TPayload }
  ){
    if(payload != null){
      Object.assign(this, payload)
    }
  }

}